import _get from 'lodash/get';
import { PMCConfig, ProviderMatchProviderV9 } from '@kyruus/types';
import {
  PurposeOptionsByRelationship,
  PurposeOption,
  AppointmentInfo
} from '@kyruus/provider-components';

import { ESTABLISHED_PATIENT, NEW_PATIENT } from './constants';

const getDefaultAppointmentPurposeEnabled = (
  config: PMCConfig,
  provider: ProviderMatchProviderV9
) => {
  const isPrimaryCare = provider.is_primary_care ?? false;
  const isSpecialtyCare = provider.is_specialty_care ?? false;

  if (isSpecialtyCare) {
    return _get(
      config,
      'feature_flags.provider_specialty_booking_use_default_appt_purpose',
      false
    );
  } else if (isPrimaryCare) {
    return _get(
      config,
      'feature_flags.provider_pcp_booking_use_default_appt_purpose',
      true
    );
  }

  return false;
};

const sortPurposeOptions = (
  purposes: PurposeOptionsByRelationship
): PurposeOptionsByRelationship => ({
  new: [...purposes.new].sort((a, b) => a.name.localeCompare(b.name)),
  established: [...purposes.established].sort((a, b) =>
    a.name.localeCompare(b.name)
  )
});

const getDefaultAppointmentInfo = (
  purposes: PurposeOptionsByRelationship,
  isDefaultPurposeEnabled: boolean
): AppointmentInfo => {
  const newDefaultPurpose = purposes.new.find(
    (purpose: PurposeOption) => purpose.default
  );

  const establishedDefaultPurpose = purposes.established.find(
    (purpose: PurposeOption) => purpose.default === true
  );

  if (newDefaultPurpose) {
    return {
      relationship: NEW_PATIENT,
      purpose: newDefaultPurpose.name
    };
  } else if (establishedDefaultPurpose) {
    return {
      relationship: ESTABLISHED_PATIENT,
      purpose: establishedDefaultPurpose.name
    };
  } else if (isDefaultPurposeEnabled) {
    const firstPurposeRelationship = purposes.new[0]
      ? NEW_PATIENT
      : ESTABLISHED_PATIENT;
    const firstPurpose = purposes.new[0] || purposes.established[0];
    return {
      relationship: firstPurposeRelationship,
      purpose: firstPurpose.name
    };
  }

  const firstPurposeRelationship = purposes.new[0]
    ? NEW_PATIENT
    : ESTABLISHED_PATIENT;
  return {
    relationship: firstPurposeRelationship
  };
};

/**
 * Given a provider, will return a list of purpose options by patientRel
 * @param {ProviderMatchProviderV9} provider
 */
export const getAppointmentOptions = (
  config: PMCConfig,
  provider: ProviderMatchProviderV9,
  appointmentInfo: AppointmentInfo,
  purposes: PurposeOptionsByRelationship
): {
  purposeOptionsByRelationship: PurposeOptionsByRelationship;
  initialAppointmentInfo: AppointmentInfo;
} => {
  const purposeOptionsByRelationship = sortPurposeOptions(purposes);
  const isDefaultPurposeEnabled = getDefaultAppointmentPurposeEnabled(
    config,
    provider
  );
  const appointmentInfoDefined =
    !!appointmentInfo.relationship || !!appointmentInfo.purpose;
  const initialAppointmentInfo = appointmentInfoDefined
    ? appointmentInfo
    : getDefaultAppointmentInfo(purposes, isDefaultPurposeEnabled);

  return {
    purposeOptionsByRelationship,
    initialAppointmentInfo
  };
};
