import { ProviderMatchProviderV9 } from '@kyruus/types';

/**
 * Return a comma delimited string of provider specialties
 * @param provider {ProviderMatchProviderV9}
 * @returns {string}
 */
export const getProviderSpecialties = (provider: ProviderMatchProviderV9) => {
  if (!provider) return '';

  return provider.specialties
    .filter((specialty) => !!specialty.name)
    .map((specialty) => specialty.name)
    .join(', ');
};
