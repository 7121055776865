import _get from 'lodash/get';

import {
  PMCConfig,
  ProviderMatchProviderV9,
  PMCSlotStatuses
} from '@kyruus/types';

import {
  AppointmentSlots,
  ExtendedProvider,
  SlotsByProviderId,
  SlotsStatusByProviderId
} from 'Src/types';

export const getProviderContactPhone = (provider: ProviderMatchProviderV9) =>
  provider.contacts.find((contact) => contact.contact_type === 'phone')?.value;

export const getProviderSlotsAndAppointmentInfo = (
  provider: ProviderMatchProviderV9,
  slotsByProviderId: SlotsByProviderId
): AppointmentSlots | undefined => {
  if (!slotsByProviderId || !slotsByProviderId[provider.id]) return;

  return slotsByProviderId[provider.id];
};

export const getProviderSlotsStatus = (
  provider: ProviderMatchProviderV9,
  slotsStatus: SlotsStatusByProviderId
): PMCSlotStatuses | undefined => {
  if (!slotsStatus || !slotsStatus[provider.id]) return;

  return slotsStatus[provider.id];
};

export const getActiveContactPanels = (
  config: PMCConfig,
  provider: ExtendedProvider
) => {
  const providerPhone = getProviderContactPhone(provider);
  const virtualCareOrg = _get(config, 'modal_display.virtual_care');
  const showBookOnlineOnlyWithAvailability = _get(
    config,
    'modal_display.show_book_online_only_with_availability'
  );

  const bookOnline =
    Boolean(provider.book_online_url) &&
    Boolean(
      !showBookOnlineOnlyWithAvailability ||
        (showBookOnlineOnlyWithAvailability &&
          provider.has_provider_availability)
    );

  const phone = Boolean(providerPhone);
  const requestForm = Boolean(provider.request_appointment_url);
  const virtualCare =
    Boolean(provider.virtual_care_url) && Boolean(virtualCareOrg);

  const fallback =
    showBookOnlineOnlyWithAvailability &&
    Boolean(provider.has_provider_availability) === false &&
    [bookOnline, phone, requestForm, virtualCare].every((panel) => !panel);

  return {
    bookOnline,
    fallback,
    phone,
    requestForm,
    virtualCare
  };
};
