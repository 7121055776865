import { useEffect, useMemo } from 'react';

import {
  getProviderDisplayName,
  getProviderContactPhone,
  PurposeOptionsByRelationship,
  AppointmentInfo
} from '@kyruus/provider-components';
import { PMCConfig } from '@kyruus/types';

import { shouldRenderDirectBookInDrawer } from 'Common/config';
import { ExtendedProvider, Log } from 'Src/types';
import { getAppointmentOptions } from 'Src/utils/availability';

interface UseAvailabilityProps {
  appointmentInfo: AppointmentInfo;
  config: PMCConfig;
  provider: ExtendedProvider;
  purposeOptionsByProviderId: Record<number, PurposeOptionsByRelationship>;
  fetchPurposesByProviderId: (providerId: number, log: Log) => Promise<void>;
  availabilityError: string;
  purposesLoading: boolean;
  log: Log;
}

export const useAvailability = ({
  config,
  provider,
  appointmentInfo,
  purposeOptionsByProviderId,
  fetchPurposesByProviderId,
  availabilityError,
  purposesLoading,
  log
}: UseAvailabilityProps) => {
  const providerName = getProviderDisplayName(provider);
  const providerPhoneNumber = getProviderContactPhone(provider);
  const renderSlotsAsButtons = shouldRenderDirectBookInDrawer(config);

  const purposes = purposeOptionsByProviderId
    ? purposeOptionsByProviderId[provider.id]
    : null;

  useEffect(() => {
    if (purposes == null) {
      fetchPurposesByProviderId(provider.id, log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purposes, provider.id]);

  const appointmentOptions = useMemo(() => {
    if (purposes == null) return;

    return getAppointmentOptions(config, provider, appointmentInfo, purposes);
  }, [appointmentInfo, config, provider, purposes]);

  const loading = purposesLoading || (purposes == null && !availabilityError);

  return {
    providerName,
    providerPhoneNumber,
    renderSlotsAsButtons,
    appointmentOptions,
    loading
  };
};
