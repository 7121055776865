import React from 'react';
import {
  injectIntl,
  WrappedComponentProps,
  FormattedMessage
} from '@kyruus/intl';
import { ShimmeringLoaderBar } from '@kyruus/loaders-and-spinners';
import { ErrorAvailabilityTiles } from '@kyruus/availability-tiles';
import { messages } from '@kyruus/provider-components';

import { LoadingBookingOptionsMessage } from './styles';

interface LoadingProps extends WrappedComponentProps {
  loading: boolean;
  availabilityError: string;
}

export const Loading = injectIntl(
  ({ loading, availabilityError, intl }: LoadingProps) => {
    const loadingMessage = loading
      ? messages.ariaLiveLoadingBookingInformation
      : messages.ariaLiveLoadingBookingInformationComplete;
    const errorExists = Boolean(availabilityError);

    return (
      <>
        {loading && <ShimmeringLoaderBar />}
        <LoadingBookingOptionsMessage
          data-testid="booking-options-loading"
          aria-live="polite"
          aria-atomic="true"
        >
          <FormattedMessage {...loadingMessage} />
        </LoadingBookingOptionsMessage>
        {errorExists && (
          <ErrorAvailabilityTiles
            hasRetries={false}
            noMoreRetriesMsg={intl.formatMessage(
              messages.availabilityNoMoreRetriesMsg
            )}
          />
        )}
      </>
    );
  }
);

Loading.displayName = 'Loading';
