import styled from '@emotion/styled';

import { fromTheme } from '@kyruus/ui-theme';

export const Header = styled.h2`
  font-size: ${fromTheme('font_size_heading_5')};
`;

export const ProviderSummary = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-areas: 'image name' 'image specialties';
  align-items: start;
  row-gap: ${fromTheme('spacing_xsmall')};
  column-gap: ${fromTheme('spacing_medium')};
  font-size: ${fromTheme('font_size')};
  margin-bottom: ${fromTheme('spacing_large')};
`;

export const ProviderImage = styled.img`
  grid-area: image;

  flex: 0;
  display: block;
  max-height: 40px;
  height: auto;
  width: auto;
  border-radius: ${fromTheme('border_radius')};
`;
export const ProviderName = styled.div`
  grid-area: name;
  font-size: ${fromTheme('font_size_heading_5')};
`;
export const ProviderSpecialties = styled.div`
  grid-area: specialties;
`;

export const LoadingBookingOptionsMessage = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
